import { BaseGameController } from '@/core/base-game.controller';
import { cardsService } from '@/state/cards/cards.service';
import { moveHistory } from '@/core/move-history';
import { cardsQuery } from '@/state/cards/cards.query';
import { Card, CardOwner, GameStatus, Suit } from '@/core/models';
import { range } from 'lodash';
import { coreBus } from '@/core/core-bus';
import { gameQuery } from '@/state/game/game.query';

export class GameController extends BaseGameController {
    constructor() {
        super();
        this.initStage();
        this.subscribeTo(coreBus.gameMoveCompletedEvent$, () => {
            this.enableDisableDragForAllCards();
        });
        this.subscribeTo(gameQuery.gameStatus$, (status) => {
            if (status == GameStatus.dealCompleted) {
                if (process.env.NODE_ENV === 'development') {
                    // setTimeout(() => this.setCustomBoard(), 2000);
                }
            }
        });
    }

    private initStage() {
        const suits = [Suit.Diamond, Suit.Spade, Suit.Club, Suit.Heart];
        const cards = [1, 2].flatMap((d) => {
            return suits.flatMap((s) => {
                return range(1, 14).map(
                    (r) =>
                        ({
                            id: `${r}${s}${d}`,
                            name: `${r}${s}`,
                            suit: s,
                            rank: r,
                            order: 0,
                            dragEnabled: false,
                            owner: CardOwner.stock,
                        } as Card)
                );
            });
        });
        cardsService.init(cards);
    }

    protected initGame(): void {
        cardsService.resetAllCards(CardOwner.stock);
        moveHistory.reset();
    }

    protected isGameCompleted(): boolean {
        return cardsQuery.getAll().filter((c) => c.owner == CardOwner.foundation).length == 104;
    }

    private enableDisableDragForAllCards() {
        const tops = cardsQuery.getTopTableauCards().map((c) => ({
            ...c,
            dragEnabled: true,
        }));

        const topIds = tops.map((x) => x.id);
        const noneTops = cardsQuery
            .getAllByOwner(CardOwner.tableau)
            .filter((c) => topIds.indexOf(c.id) < 0)
            .map((c) => ({
                ...c,
                dragEnabled: false,
            }));

        cardsService.upsertMany(tops.concat(noneTops));
    }

    private setCustomBoard() {
        const board: { owner: CardOwner; index: number; cards: string[] }[] = [
            { owner: CardOwner.tableau, index: 1, cards: ['', '', '', ''] },
            { owner: CardOwner.tableau, index: 2, cards: ['', '', '', ''] },
            { owner: CardOwner.tableau, index: 3, cards: ['', '', '', ''] },
            { owner: CardOwner.tableau, index: 4, cards: ['', '', '', ''] },
            { owner: CardOwner.tableau, index: 5, cards: ['', '', '', ''] },
            { owner: CardOwner.tableau, index: 6, cards: ['', '', '', ''] },
            { owner: CardOwner.tableau, index: 7, cards: ['', '', '', ''] },
            { owner: CardOwner.tableau, index: 8, cards: ['12D1', '13D1', '', ''] },
            { owner: CardOwner.tableau, index: 9, cards: ['', '', '', ''] },
            { owner: CardOwner.tableau, index: 10, cards: ['', '', '', ''] },
            {
                owner: CardOwner.foundation,
                index: 1,
                cards: ['1H1', '2H1', '3H1', '4H1', '5H1', '6H1', '7H1', '8H1', '9H1'],
            },
            {
                owner: CardOwner.foundation,
                index: 2,
                cards: ['1S1', '2S1', '3S1', '4S1', '5S1', '6S1', '7S1', '8S1', '9S1'],
            },
            {
                owner: CardOwner.foundation,
                index: 3,
                cards: ['1D1', '2D1', '3D1', '4D1', '5D1', '6D1', '7D1', '8D1', '9D1'],
            },
            {
                owner: CardOwner.foundation,
                index: 4,
                cards: ['1C1', '2C1', '3C1', '4C1', '5C1', '6C1', '7C1', '8C1', '9C1'],
            },
            {
                owner: CardOwner.foundation,
                index: 5,
                cards: ['1H2', '2H2', '3H2', '4H2', '5H2', '6H2', '7H2', '8H2', '9H2'],
            },
            {
                owner: CardOwner.foundation,
                index: 6,
                cards: ['1S2', '2S2', '3S2', '4S2', '5S2', '6S2', '7S2', '8S2', '9S2'],
            },
            {
                owner: CardOwner.foundation,
                index: 7,
                cards: ['1D2', '2D2', '3D2', '4D2', '5D2', '6D2', '7D2', '8D2', '9D2'],
            },
            {
                owner: CardOwner.foundation,
                index: 8,
                cards: ['1C2', '2C2', '3C2', '4C2', '5C2', '6C2', '7C2', '8C2', '9C2'],
            },
        ];

        const avails = cardsQuery.getAll().filter((card) => {
            for (let i = 0; i < board.length; i++) {
                if (board[i].cards.indexOf(card.id) >= 0) {
                    return false;
                }
            }
            return true;
        });

        cardsService.update(null, {
            owner: CardOwner.none,
        });

        const updates = board.flatMap((item) => {
            return item.cards.flatMap((id, i) => {
                const card = id != '' ? cardsQuery.getEntity(id) : avails.pop();
                if (card) {
                    return [
                        {
                            // eslint-disable-next-line
                            ...card!,
                            isFaceUp: true,
                            dragEnabled: false,
                            owner: item.owner,
                            ownerIndex: item.index,
                            order: i + 1,
                        },
                    ];
                } else {
                    return [];
                }
            });
        });

        cardsService.upsertMany(updates);
        cardsQuery.getAll().forEach((c) => {
            coreBus.cardMoveCmd$.next({
                cardId: c.id,
                duration: 0,
            });
        });

        this.enableDisableDragForAllCards();
    }
}
