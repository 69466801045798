import { coreBus } from '@/core/core-bus';
import { CardOwner } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { moveHistory } from '@/core/move-history';
import { cardsService } from '@/state/cards/cards.service';
import { gameService } from '@/state/game/game.service';
import { judge } from '@/games/forty/judge';
import { CardMoveBaseController } from '@/core/card-move-base.controller';
import { cardSound } from '@/core/sound';

export class MoveController extends CardMoveBaseController {
    constructor() {
        super({
            enableTableauAutoAdjust: true,
            meldCard: {
                validate: (cmd) => judge.canPutCardOnTopOf(cmd.card, cmd.destCard),
            },
            moveCardToEmptyTableauCmd: {
                validate: (cmd) => judge.canMoveToTableauEmptyFrame(cmd.card, cmd.tableauIndex),
            },
            moveCardToFoundation: {
                validate: (cmd) => judge.canPutInFoundation(cmd.card, cmd.foundationIndex),
            },
        });
        this.subscribeTo(coreBus.stockClickEvent$, () => {
            this.dealNextCard();
            coreBus.gameMoveCompletedEvent$.next();
        });
    }

    private dealNextCard() {
        if (!this.canMakeMove()) {
            return;
        }

        const top = cardsQuery.getTopByOwner(CardOwner.stock);
        if (!top) {
            return;
        }

        const topWaste = cardsQuery.getTopByOwner(CardOwner.waste);
        const order = topWaste ? topWaste.order + 1 : 1;

        moveHistory.startMove();

        cardsService.update(top.id, {
            order,
            owner: CardOwner.waste,
            ownerIndex: 1,
            dragEnabled: true,
            isFaceUp: true,
        });
        moveHistory.addState(top);

        coreBus.cardMoveCmd$.next({
            duration: 300,
            cardId: top.id,
        });

        gameService.increaseMoveCounter();
        moveHistory.endMove();
        cardSound.playMove();
    }
}
