import { CardDealerBaseController } from '@/core/card-dealer.base.controller';
import { cardsQuery } from '@/state/cards/cards.query';
import { CardOwner } from '@/core/models';
import { cardsService } from '@/state/cards/cards.service';
import { coreBus } from '@/core/core-bus';

export class DealerController extends CardDealerBaseController {
    constructor() {
        super(30, 40);
    }

    protected dealCard(i: number): void {
        const card = cardsQuery.getTopByOwner(CardOwner.stock);
        if (!card) {
            throw new Error('no card in stock');
        }

        // tableau 1
        if (i >= 1 && i <= 4) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 1,
                order: i,
                isFaceUp: true,
                dragEnabled: i == 4,
            });
        }

        // tableau 2
        if (i >= 5 && i <= 8) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 2,
                order: i - 4,
                isFaceUp: true,
                dragEnabled: i == 8,
            });
        }

        // tableau 3
        if (i >= 9 && i <= 12) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 3,
                order: i - 8,
                isFaceUp: true,
                dragEnabled: i == 12,
            });
        }

        // tableau 4
        if (i >= 13 && i <= 16) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 4,
                order: i - 12,
                isFaceUp: true,
                dragEnabled: i == 16,
            });
        }

        // tableau 5
        if (i >= 17 && i <= 20) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 5,
                order: i - 16,
                isFaceUp: true,
                dragEnabled: i == 20,
            });
        }

        // tableau 6
        if (i >= 21 && i <= 24) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 6,
                order: i - 20,
                isFaceUp: true,
                dragEnabled: i == 24,
            });
        }

        // tableau 7
        if (i >= 25 && i <= 28) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 7,
                order: i - 24,
                isFaceUp: true,
                dragEnabled: i == 28,
            });
        }

        // tableau 8
        if (i >= 29 && i <= 32) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 8,
                order: i - 28,
                isFaceUp: true,
                dragEnabled: i == 32,
            });
        }

        // tableau 9
        if (i >= 33 && i <= 36) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 9,
                order: i - 32,
                isFaceUp: true,
                dragEnabled: i == 36,
            });
        }

        // tableau 10
        if (i >= 37 && i <= 40) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 10,
                order: i - 36,
                isFaceUp: true,
                dragEnabled: i == 40,
            });
        }

        coreBus.cardMoveCmd$.next({
            duration: 0,
            cardId: card.id,
        });
    }
}
