import { Card, CardOwner } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { coreUtil } from '@/core/core-util';

export class Judge {
    canPutCardOnTopOf(card: Card, destCard: Card): boolean {
        return card.rank + 1 == destCard.rank && card.suit == destCard.suit;
    }

    canPutInFoundation(card: Card, foundationIndex: number): boolean {
        if (card.owner == CardOwner.foundation) {
            return false;
        }

        const suit = coreUtil.getFoundationSuitByIndex(foundationIndex);
        if (card.suit != suit) {
            return false;
        }

        // if card owned by tableau it should be at the top
        if (card.owner == CardOwner.tableau) {
            const top = cardsQuery.getTopByOwnerAndIndex(card.owner, card.ownerIndex);
            if (!top || card.id != top.id) {
                return false;
            }
        }

        const top = cardsQuery.getTopByOwnerAndIndex(CardOwner.foundation, foundationIndex);
        if (!top) {
            return card.rank == 1;
        }
        return top.rank + 1 == card.rank;
    }

    canMoveToTableauEmptyFrame(card: Card, tableauIndex: number) {
        return !cardsQuery.getTopByOwnerAndIndex(CardOwner.tableau, tableauIndex);
    }
}

export const judge = new Judge();
