
import { defineComponent, ref } from 'vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import { gameQuery } from '@/state/game/game.query';
import SuitFrame from '@/components/SuitFrame.vue';
import EmptyFrame from '@/components/EmptyFrame.vue';
import { display } from '@/games/forty/display';
import CardStock from '@/components/CardStock.vue';
import { recomputable } from '@/composable/recomputable';

export default defineComponent({
    components: {
        SuitFrame,
        EmptyFrame,
        CardStock,
    },

    setup() {
        const foundationHighlight = ref(0);
        const recompute = ref(0);

        subscribeTo(gameQuery.foundationIndexHighlight$, (index) => {
            if (index) {
                foundationHighlight.value = index;
            } else {
                foundationHighlight.value = 0;
            }
        });

        subscribeTo(gameQuery.gameSize$, () => {
            setTimeout(() => {
                recompute.value += 1;
            });
        });

        const getFrameStyle = (x: number, y: number) => {
            return {
                top: `${y}px`,
                left: `${x}px`,
                width: `${display.cardSize.width}px`,
                height: `${display.cardSize.height}px`,
                borderRadius: `${display.cardSize.width * 0.06}px`,
            };
        };

        const getFoundation = () => {
            return {
                lineHeight: `${display.cardSize.height}px`,
                fontSize: `${display.cardSize.height * 0.3}px`,
            };
        };

        const foundation1Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation1Position.x,
                display.foundation1Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation2Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation2Position.x,
                display.foundation2Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation3Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation3Position.x,
                display.foundation3Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation4Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation4Position.x,
                display.foundation4Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation5Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation5Position.x,
                display.foundation5Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation6Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation6Position.x,
                display.foundation6Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation7Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation7Position.x,
                display.foundation7Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const foundation8Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation8Position.x,
                display.foundation8Position.y
            );
            return {
                ...frame,
                ...getFoundation(),
            };
        });

        const stockStyle = recomputable(recompute, () => {
            return {
                top: `${display.stockPosition.y}px`,
                left: `${display.stockPosition.x}px`,
            };
        });

        const restartIconStyle = recomputable(recompute, () => {
            const s = display.cardSize.width * 0.4;
            return {
                height: `${s}px`,
                width: `${s}px`,
            };
        });

        const tableau1Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau1Position.x, display.tableau1Position.y);
        });

        const tableau2Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau2Position.x, display.tableau2Position.y);
        });

        const tableau3Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau3Position.x, display.tableau3Position.y);
        });

        const tableau4Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau4Position.x, display.tableau4Position.y);
        });

        const tableau5Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau5Position.x, display.tableau5Position.y);
        });

        const tableau6Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau6Position.x, display.tableau6Position.y);
        });

        const tableau7Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau7Position.x, display.tableau7Position.y);
        });

        const tableau8Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau8Position.x, display.tableau8Position.y);
        });

        const tableau9Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau9Position.x, display.tableau9Position.y);
        });

        const tableau10Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau10Position.x, display.tableau10Position.y);
        });

        return {
            foundationHighlight,
            foundation1Style,
            foundation2Style,
            foundation3Style,
            foundation4Style,
            foundation5Style,
            foundation6Style,
            foundation7Style,
            foundation8Style,
            stockStyle,
            restartIconStyle,
            tableau1Style,
            tableau2Style,
            tableau3Style,
            tableau4Style,
            tableau5Style,
            tableau6Style,
            tableau7Style,
            tableau8Style,
            tableau9Style,
            tableau10Style,
        };
    },
});
