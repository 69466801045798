import { CardAutoMoveBaseController } from '@/core/card-auto-move.base.controller';
import { Card, CardOwner, Hint } from '@/core/models';
import { judge } from '@/games/forty/judge';
import { coreBus } from '@/core/core-bus';
import { cardsQuery } from '@/state/cards/cards.query';
import { orderBy, uniqBy } from 'lodash';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

export class AutoMoveController extends CardAutoMoveBaseController {
    protected doBestMove(card: Card): void {
        if (!(card.owner == CardOwner.waste || card.owner == CardOwner.tableau)) {
            return;
        }

        // safe guard check if card is face up
        if (!card.isFaceUp) {
            return;
        }

        // try to move card to foundation
        for (let i = 1; i <= 8; i++) {
            if (judge.canPutInFoundation(card, i)) {
                coreBus.moveCardToFoundationCmd$.next({
                    card,
                    foundationIndex: i,
                });
                return;
            }
        }

        // try to meld card
        const tableauTops = cardsQuery.getTopTableauCards();
        for (let i = 0; i < tableauTops.length; i++) {
            const c = tableauTops[i];
            if (judge.canPutCardOnTopOf(card, c)) {
                coreBus.meldCardCmd$.next({
                    card,
                    destCard: c,
                });
                return;
            }
        }

        // try to move king card to empty tableau
        for (let i = 1; i <= 7; i++) {
            if (judge.canMoveToTableauEmptyFrame(card, i)) {
                coreBus.moveCardToEmptyTableauCmd$.next({
                    card,
                    tableauIndex: i,
                });
                return;
            }
        }

        // if we got here we could find any auto move
        // we want to shake the card
        coreBus.shakeCardCmd$.next({
            card,
        });
    }

    protected isAutoFinishPossible(): boolean {
        // check all cards in tableau or foundation
        const count = cardsQuery
            .getAll()
            .filter((c) => c.owner == CardOwner.foundation || c.owner == CardOwner.tableau).length;
        if (count != 104) {
            return false;
        }

        // check all cards in tableau ordered by rank
        for (let t = 1; t <= 10; t++) {
            const cards = cardsQuery.getOrderedByOwnerAndIndex(CardOwner.tableau, t);
            if (cards.length <= 1) {
                continue;
            }
            for (let i = 1; i < cards.length; i++) {
                if (cards[i - 1].rank <= cards[i].rank) {
                    return false;
                }
            }
        }

        return true;
    }

    protected doAutoFinish(done: () => void): void {
        const cards = cardsQuery.getAllByOwner(CardOwner.tableau);
        const ordered = orderBy(cards, (c) => c.rank);

        const sub = interval(100)
            .pipe(take(ordered.length))
            .subscribe({
                next: (i) => {
                    const card = ordered[i];
                    for (let i = 1; i <= 8; i++) {
                        if (judge.canPutInFoundation(card, i)) {
                            coreBus.moveCardToFoundationCmd$.next({
                                card,
                                foundationIndex: i,
                            });
                            return;
                        }
                    }
                },
                complete: () => {
                    done();
                },
            });

        this.subscription.add(sub);
    }

    protected generateHints(): Hint[] {
        const tabTops = cardsQuery.getTopTableauCards();
        const tabCards = cardsQuery.getAllByOwner(CardOwner.tableau).filter((c) => c.dragEnabled);
        const topWaste = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 1);
        const tabAndWasteCards = topWaste ? tabCards.concat([topWaste]) : tabCards;

        // hints for tableau
        const tabHints = tabAndWasteCards.flatMap((c) => {
            return tabTops
                .filter((tc) => tc.ownerIndex != c.ownerIndex && judge.canPutCardOnTopOf(c, tc))
                .map(
                    (tc) =>
                        ({
                            card1Id: c.id,
                            card2Id: tc.id,
                            priority: 1,
                        } as Hint)
                );
        });

        // hints for foundation
        const foundHints = tabAndWasteCards.flatMap((c) => {
            const hints = [];
            for (let i = 1; i <= 8; i++) {
                if (judge.canPutInFoundation(c, i)) {
                    const foundCard = cardsQuery.getTopByOwnerAndIndex(CardOwner.foundation, i);
                    hints.push({
                        card1Id: c.id,
                        card2Id: foundCard ? foundCard.id : null,
                        foundationIndex: foundCard ? null : i,
                        priority: 0,
                    } as Hint);
                }
            }
            return uniqBy(hints, (h) => h.card1Id);
        });

        return tabHints.concat(foundHints);
    }
}
